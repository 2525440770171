import gql from 'graphql-tag'

// 微信公众号授权
export const memberAuthEntrance = gql`
    query memberAuthEntrance($appId: String!, $redirectUri: String!, $type: Int!) {
        memberAuthEntrance(appId: $appId, redirectUri: $redirectUri, type: $type) {
            code
            message
            data
        }
    }
`

// 注册发送验证码
export const getSmsCode = gql`
    query sendValidationCode($mobile: String!, $scanId: String) {
        sendValidationCode(mobile: $mobile, scanId: $scanId) {
            code
            message
        }
    }
`

// 绑定手机号
export const bindMemberMobile = gql`
    mutation bindMemberMobile($mobile: String!, $code: String!) {
        bindMemberMobile(mobile: $mobile, code: $code) {
            code
            message
        }
    }
`

// 注册用户信息
export const registerMember = gql`
    mutation registerMember($mobile: String!, $sex: Int!, $height: Int!, $birthday: String!) {
        registerMember(mobile: $mobile, sex: $sex, height: $height, birthday: $birthday) {
            code
            message
        }
    }
`

// 微信端用户登录
export const memberLogin = gql`
    query memberLogin($type: Int!, $appId: String!, $code: String!, $scanId: String, $deviceId: String) {
        memberLogin(type: $type, appId: $appId, code: $code, scanId: $scanId, deviceId: $deviceId) {
            code
            message
            data {
                token
                expiresIn
            }
        }
    }
`

// 用户APP登录
export const appLogin = gql`
    query appLogin($token: String, $mobile: String, $thirdUid: String) {
        appLogin(token: $token, mobile: $mobile, thirdUid: $thirdUid) {
            code
            message
            data {
                token
                expiresIn
            }
        }
    }
`

// 获取最后一组扫描信息
export const findLatestScanTypeInfo = gql`
    query findLatestScanTypeInfo($deviceType: Int, $type: Int) {
        findLatestScanTypeInfo(deviceType: $deviceType, type: $type ) {
            code
            message
            data {
                scanId
                memberId
                deviceId
                biaMeasure
                staticEval
                bsScanInfo {
                    scanId
                    percent
                    result
                    createTime
                    beginTime
                    endTime
                }
                bmBdaScanInfo {
                    scanId
                    percent
                    result
                    createTime
                    beginTime
                    endTime
                }
                bmBiaScanInfo {
                    result
                    percent
                }
                createTime
                viewStatus
                shoulderJointEval
                bsShoulderScanInfo {
                    result
                },
                girthMeasure,
                scanTime,
                deviceType
				utc
            }
        }
    }
`

// 获取当前扫描信息
export const findScanTypeInfo = gql`
    query findScanTypeInfo($scanId: String!) {
        findScanTypeInfo(scanId: $scanId) {
            code
            message
            data {
                scanId
                memberId
                deviceId
                biaMeasure
                staticEval
                bsScanInfo {
                    scanId
                    percent
                    result
                    createTime
                    beginTime
                    endTime
                }
                bmBdaScanInfo {
                    scanId
                    percent
                    result
                    createTime
                    beginTime
                    endTime
                }
                bmBiaScanInfo {
                    result
                    percent
                }
                createTime
                viewStatus
                shoulderJointEval
                bsShoulderScanInfo {
                    result
                },
                girthMeasure,
                scanTime,
                deviceType
                isNewMathTt
				utc
            }
        }
    }
`

// 模型生成失败判断是否拥有历史数据
export const findScanTrueHistory = gql`
    query findScanTrueHistory ($deviceType: Int){
        findScanTrueHistory(deviceType: $deviceType) {
            code
            message
            data {
                scanId
                memberId
                memberId
                deviceId
                biaMeasure
                staticEval
                evalStatus
                bdaStatus
                biaStatus
                createTime
                bsShoulderStatus,
                girthMeasure,
                shoulderJointEval,
                deviceType,
                scanTime
				utc
            }
        }
    }
`

// 获取用户信息
export const getMemberInfo = gql`
    query getMemberInfo {
        getMemberInfo {
            code
            message
            data {
                id
                mobile
                name
                sex
                height
                age
                nickName
                avatar
                birthday
            }
        }
    }
`

// 获取身体分数数据
export const findBodyScore = gql`
    query findBodyScore($scanId: String, $scanTime: Int, $scanType: Int) {
        findBodyScore(scanId: $scanId, scanTime: $scanTime, scanType: $scanType) {
            code
            message
            data {
                scanId
                bodyScore
                massLevel {
                    id
                    conclusion
                    advice
                }
                massScore
                evalLevel {
                    id
                    conclusion
                    advice
                }
                evalScore
                createTime
                massConScore
                evalConScore
            }
        }
    }
`

// 获取身体状态
export const findBodyState = gql`
    query findBodyState($scanId: String) {
        findBodyState(scanId: $scanId) {
            code
            message
            data {
                bodyShare
                WT {
                    l
                    m
                    h
                    v
                    status
                }
                PBF {
                    l
                    m
                    h
                    v
                    status
                }
                LM {
                    l
                    m
                    h
                    v
                    status
                }
            }
        }
    }
`

// 获取节段数据(脂肪&&肌肉)
export const bmSegInfo = gql`
    query bmSegInfo($scanId: String) {
        bmSegInfo(scanId: $scanId) {
            code
            message
            data {
                bmSegLM {
                    LMTR {
                        l
                        m
                        h
                        v
                        status
                    }
                    LMRA {
                        l
                        m
                        h
                        v
                        status
                    }
                    LMLA {
                        l
                        m
                        h
                        v
                        status
                    }
                    LMRL {
                        l
                        m
                        h
                        v
                        status
                    }
                    LMLL {
                        l
                        m
                        h
                        v
                        status
                    }
                }
                bmSegBFM {
                    BFMTR {
                        l
                        m
                        h
                        v
                        status
                    }
                    BFMRA {
                        l
                        m
                        h
                        v
                        status
                    }
                    BFMLA {
                        l
                        m
                        h
                        v
                        status
                    }
                    BFMRL {
                        l
                        m
                        h
                        v
                        status
                    }
                    BFMLL {
                        l
                        m
                        h
                        v
                        status
                    }
                }
            }
        }
    }
`

// 获取节段对比数据(脂肪&&肌肉)
export const bmSegContrastInfo = gql`
    query bmSegContrastInfo($memberId: Int, $scanId: String, $tcScanId: String) {
        bmSegContrastInfo(memberId: $memberId, scanId: $scanId, tcScanId: $tcScanId) {
            code
            message
            data {
                bmSegLMContrast {
                    LMTR {
                        v
                        tcV
                    }
                    LMRA {
                        v
                        tcV
                    }
                    LMLA {
                        v
                        tcV
                    }
                    LMRL {
                        v
                        tcV
                    }
                    LMLL {
                        v
                        tcV
                    }
                }
                bmSegBFMContrast {
                    BFMTR {
                        v
                        tcV
                    }
                    BFMRA {
                        v
                        tcV
                    }
                    BFMLA {
                        v
                        tcV
                    }
                    BFMRL {
                        v
                        tcV
                    }
                    BFMLL {
                        v
                        tcV
                    }
                }
            }
        }
    }
`

// 获取用户体测BIA合成成功历史记录
export const findBmBIAScanTrueHistory = gql`
    query findBmBIAScanTrueHistory($memberId: Int, $scanTime: Int, $deviceType: Int) {
        findBmBIAScanTrueHistory(memberId: $memberId, scanTime: $scanTime, deviceType: $deviceType) {
            code
            message
            data {
                year
                data {
                    scanId
                    createDate
                    createTime
					utc
                }
            }
        }
    }
`

// 获取用户体态合成成功历史记录
export const findBsScanTrueHistory = gql`
    query findBsScanTrueHistory($memberId: Int, $scanTime: Int, $deviceType: Int) {
        findBsScanTrueHistory(memberId: $memberId, scanTime: $scanTime, deviceType: $deviceType) {
            code
            message
            data {
                year
                data {
                    scanId
                    createDate
                    createTime
					utc
                }
            }
        }
    }
`

// 获取围度对比信息
export const bmGirthContrasInfo = gql`
    query bmGirthContrasInfo($memberId: Int, $scanId: String, $tcScanId: String) {
        bmGirthContrasInfo(memberId: $memberId, scanId: $scanId, tcScanId: $tcScanId) {
            code
            message
            data {
                latestBmGirth {
                    bustGirth
                    waistGirth
                    hipGirth
                    leftUpperArmGirth
                    rightUpperArmGirth
                    leftThighGirth
                    rightThighGirth
                    leftCalfGirth
                    rightCalfGirth
                    height,
                    neckGirth,
                    leftMidThighGirth,
                    rightMidThighGirth,
                    leftMinThighGirth,
                    rightMinThighGirth,
                    midWaistGirth,
                    lowWaistGirth
                }
                contrastBmGirth {
                    bustGirth
                    waistGirth
                    hipGirth
                    leftUpperArmGirth
                    rightUpperArmGirth
                    leftThighGirth
                    rightThighGirth
                    leftCalfGirth
                    rightCalfGirth
                    height,
                    neckGirth,
                    leftMidThighGirth,
                    rightMidThighGirth,
                    leftMinThighGirth,
                    rightMinThighGirth,
                    midWaistGirth,
                    lowWaistGirth
                }
            }
        }
    }
`

// 根据scanId获取围度数据
export const bmGirthInfo = gql`
    query bmGirthInfo($scanId: String) {
        bmGirthInfo(scanId: $scanId) {
            code
            message
            data {
                bustGirth
                waistGirth
                hipGirth
                leftUpperArmGirth
                rightUpperArmGirth
                leftThighGirth
                rightThighGirth
                leftCalfGirth
                rightCalfGirth
                height,
                neckGirth,
                leftMidThighGirth,
                rightMidThighGirth,
                leftMinThighGirth,
                rightMinThighGirth,
                midWaistGirth,
                lowWaistGirth
            }
        }
    }
`

// 获取体态评估数据
export const bsEvalInfo = gql`
    query bsEvalInfo($scanId: String) {
        bsEvalInfo(scanId: $scanId) {
            code
            message
            data {
                highLowShoudler
                headSlant
                headForward
                leftLegXo
                rightLegXo
                pelvisForward
                leftKneeCheck
                rightKneeCheck
                roundShoulderLeft
                roundShoulderRight
            }
        }
    }
`

export const findBsEvalContrastInfo = gql`
    query findBsEvalContrastInfo($scanId: String!, $scanTime: Int!) {
        findBsEvalContrastInfo(scanId: $scanId, scanTime: $scanTime) {
            code
            message
            data {
                highLowShoudler
                headSlant
                headForward
                leftLegXo
                rightLegXo
                pelvisForward
                leftKneeCheck
                rightKneeCheck
                roundShoulderLeft
                roundShoulderRight
            }
        }
    }
`

export const findBsDataStateInfo = gql`
query findBsDataStateInfo($scanId: String!) {
    findBsDataStateInfo(scanId: $scanId) {
        code
        message
        data {
            id
            scanId
            highLowShoudlerState
            headSlantState
            leftLegXoState
            rightLegXoState
            headForwardState
            pelvisForwardState
            legType
            leftKneeCheckState
            rightKneeCheckState
            leftRoundShoulderState
            rightRoundShoulderState
            hsVisualization
            pfsVisualization
        }
    }
}
`

// 获取分数显示状态
export const getUserScr = gql`
    query getUserScr($deviceId: String!) {
        getUserScr(deviceId: $deviceId) {
            code
            message
            data {
                isScore
            }
        }
    }
`

// 获取身体调节数据(体型预测)
export const bodyAdjustInfo = gql`
    query bodyAdjustInfo($scanId: String) {
        bodyAdjustInfo(scanId: $scanId) {
            code
            message
            data {
                scanId
                weight
                bodyFat
                muscle
            }
        }
    }
`

// 获取体型预测合成结果
export const findBmPrediction = gql`
    query findBmPrediction($scanId: String) {
        findBmPrediction(scanId: $scanId) {
            code
            message
            data {
                result
            }
        }
    }
`

// 获取各体成分指标数据
export const findBmMassQuotaInfos = gql`
    query findBmMassQuotaInfos($memberId: Int!, $scanId: String!, $tcScanId: String, $lang: String) {
        findBmMassQuotaInfos(memberId: $memberId, scanId: $scanId, tcScanId: $tcScanId, lang: $lang) {
            code
            message
            data {
                name
                dataColumn
                unit
                latestData {
                    l
                    m
                    h
                    v
                    status
                }
                contrastData {
                    l
                    m
                    h
                    v
                    status
                }
                order
                tareCapacityWeight
            }
        }
    }
`

// 获取各体成分指标趋势对比数据
export const findBmMassQuotaTrend = gql`
    query findBmMassQuotaTrend($memberId: Int, $scanIds: [String]) {
        findBmMassQuotaTrend(memberId: $memberId, scanIds: $scanIds) {
            code
            message
            data {
                name
                dataColumn
                unit
                trendData {
                    l
                    m
                    h
                    v
                    status
                    measureTime
                }
                order
            }
        }
    }
`

// 获取围度趋势对比数据
export const findBmGirthTrend = gql`
    query getBmGirthTrend($scanIds: [String]!) {
        getBmGirthTrend(scanIds: $scanIds) {
            code
            message
            data {
                bustGirth {
                    value
                    scanTime
                }
                waistGirth {
                    value
                    scanTime
                }
                hipGirth {
                    value
                    scanTime
                }
                leftUpperArmGirth {
                    value
                    scanTime
                }
                rightUpperArmGirth {
                    value
                    scanTime
                }
                leftThighGirth {
                    value
                    scanTime
                }
                rightThighGirth {
                    value
                    scanTime
                }
                leftCalfGirth {
                    value
                    scanTime
                }
                rightCalfGirth {
                    value
                    scanTime
                }
                neckGirth {
                    value
                    scanTime
                }
                leftMidThighGirth {
                    value
                    scanTime
                }
                rightMidThighGirth {
                    value
                    scanTime
                }
                leftMinThighGirth {
                    value
                    scanTime
                }
                rightMinThighGirth {
                    value
                    scanTime
                }
                midWaistGirth {
                    value
                    scanTime
                }
            }
        }
    }
`

// 获取个性化设置列表
export const findAllQuotas = gql`
    query findAllQuotas($memberId: Int) {
        findAllQuotas(memberId: $memberId) {
            code
            message
            data {
                id
                memberId
                bmqId
                bmqName
                order
                state
            }
        }
    }
`

// 个性化设置列表选择操作
export const choiceQuota = gql`
    mutation choiceQuota($id: Int) {
        choiceQuota(id: $id) {
            code
            message
        }
    }
`

// 取消选择
export const deselectionQuota = gql`
    mutation deselectionQuota($id: Int, $order: Int, $memberId: Int) {
        deselectionQuota(id: $id, order: $order, memberId: $memberId) {
            code
            message
        }
    }
`

// 全选
export const checkAllQuota = gql`
    mutation checkAllQuota($memberId: Int) {
        checkAllQuota(memberId: $memberId) {
            code
            message
        }
    }
`

// 反选
export const deselectionAllQuota = gql`
    mutation deselectionAllQuota($memberId: Int) {
        deselectionAllQuota(memberId: $memberId) {
            code
            message
        }
    }
`

// 恢复默认
export const resetQuota = gql`
    mutation resetQuota($memberId: Int) {
        resetQuota(memberId: $memberId) {
            code
            message
        }
    }
`

// 拖动操作
export const dragQuota = gql`
    mutation dragQuota($memberId: Int, $id: Int, $order: Int, $targetOrder: Int) {
        dragQuota(memberId: $memberId, id: $id, order: $order, targetOrder: $targetOrder) {
            code
            message
        }
    }
`

// 模型接口
// 获取节段热力图信息
export const segTchartInfo = gql`
    query segTchartInfo($memberId: Int, $type: Int, $scanId: String, $tcScanId: String) {
        segTchartInfo(memberId: $memberId, type: $type, scanId: $scanId, tcScanId: $tcScanId) {
            code
            message
            data {
                stId
                scanId
                tcScanId
                compareDate
                result
                type
                fileFlag
                filePath
                picFlag
                picPath
                createtime
            }
        }
    }
`

// 获取体态模型文件
export const findBsScanFileInfos = gql`
    query findBsScanFileInfos($scanId: String) {
        findBsScanFileInfos(scanId: $scanId) {
            code
            message
            data {
                scanId
                fileFlag
                filePath
                fileType {
                    id
                    fileName
                    fileExt
                }
                createTime
            }
        }
    }
`

// 获取体测模型文件
export const findBmScanFileInfos = gql`
    query findBmScanFileInfos($scanId: String) {
        findBmScanFileInfos(scanId: $scanId) {
            code
            message
            data {
                scanId
                fileFlag
                filePath
                fileType {
                    id
                    fileName
                    fileExt
                }
                createTime
            }
        }
    }
`

// 提交反馈内容
export const appFeedback = gql`
    mutation appFeedback($content: String!, $filePaths: [String]) {
        appFeedback(content: $content, filePaths: $filePaths) {
            code
            message
        }
    }
`

// 获取上传图片的授权信息
export const getPolicyAndSign = gql`
    query getPolicyAndSign($fileName: String!, $md5: String!) {
        getPolicyAndSign(fileName: $fileName, md5: $md5) {
            code
            message
            data {
                policy
                signature
                url
            }
        }
    }
`

// 更新微信用户个人信息
export const updateMemberInfo = gql`
    mutation updateMemberInfo(
        $nickName: String
        $sex: Int
        $height: Float
        $age: Int
        $mobile: String
        $birthday: String
    ) {
        updateMemberInfo(
            nickName: $nickName
            sex: $sex
            height: $height
            age: $age
            mobile: $mobile
            birthday: $birthday
        ) {
            code
            message
        }
    }
`

// 修改手机号发送验证码
export const sendCode = gql`
    query sendCode($mobile: String!) {
        sendCode(mobile: $mobile) {
            code
            message
        }
    }
`

// 调用消息列表
export const findAppNoticeList = gql`
    query findAppNoticeList($pageNumber: Int, $pageSize: Int) {
        findAppNoticeList(pageNumber: $pageNumber, pageSize: $pageSize) {
            code
            message
            data {
                id
                title
                intro
                content
                sendTime
                readState
                createTime
            }
        }
    }
`

// 添加消息已读
export const addAppNoticeRead = gql`
    mutation addAppNoticeRead($noticeId: Int!) {
        addAppNoticeRead(noticeId: $noticeId) {
            code
            message
        }
    }
`

// 获取节段分布模型文件信息
export const getSegDisFileInfos = gql`
    query getSegDisFileInfos($scanId: String!) {
        getSegDisFileInfos(scanId: $scanId) {
            code
            message
            data {
                id
                scanId
                typeId
                fileFlag
                filePath
            }
        }
    }
`

// 获取节段对比模型文件信息
export const getSegConFileInfos = gql`
    query getSegConFileInfos($scanId: String!, $conDate: String!, $conScanId: String!) {
        getSegConFileInfos(scanId: $scanId, conDate: $conDate, conScanId: $conScanId) {
            code
            message
            data {
                id
                scanId
                typeId
                fileFlag
                filePath
            }
        }
    }
`

// 获取门店信息
export const findUserInfoByScanId = gql`
    query findUserInfoByScanId($scanId: String!) {
        findUserInfoByScanId(scanId: $scanId) {
            code
            message
            data {
                userId
                gymPhone
                gymAddr
                gymName
                qrcode
            }
        }
    }
`

// 更新scanTypeInfo信息
export const updateScanTypeInfoRowByScanId = gql`
    mutation updateScanTypeInfoRowByScanId($scanId: String!, $viewStatus: Int) {
        updateScanTypeInfoRowByScanId(scanId: $scanId, viewStatus: $viewStatus) {
            code
            message
        }
    }
`

// 获取体态重心偏移数据
export const weightOffset = gql`query weightOffset($scanId: String) {
    weightOffset(scanId: $scanId) {
        code,
        message,
        data {
            weightA,
            weightB,
            weightC,
            weightD
        }
    }
}`
// 获取肩部评估数据
export const findBsShoulderConclusion = gql`query findBsShoulderConclusion($scanId: String!, $scanTime: Int) {
    findBsShoulderConclusion(scanId: $scanId, scanTime: $scanTime) {
        code
        message
        data {
            shoulderInfo {
                leftAbuction {
                    name
                    val
                    contrast
                    conclusion
                    limit
                }
                rightAbuction {
                    name
                    val
                    contrast
                    conclusion
                    limit
                }
                leftAntexion {
                    name
                    val
                    contrast
                    conclusion
                    limit
                }
                rightAntexion {
                    name
                    val
                    contrast
                    conclusion
                    limit
                }
            }
            conclusion {
                title
                msg
            }
        }
    }
}`
// 用户登录
export const smsLogin = gql`query smsLogin($mobile: String, $code: String!, $email: String, $userId: String){
    smsLogin(mobile: $mobile, code: $code, email: $email, userId: $userId) {
        code
        message
        data {
            token
            expiresIn
            password
        }
    }
}`

// 发送短信验证码
export const sendValidationCode = gql`mutation sendValidationCode($mobile: String!, $scanId: String){
    sendValidationCode(mobile: $mobile, scanId: $scanId) {
        code
        message
    }
}`

// 新用户用户注册信息
export const register = gql`mutation register($member: MemberInput!) {
    register(member: $member) {
        code
        message
        data {
            token
            expiresIn
        }
    }
}`

// 新用户注册检验手机号
export const verifyValidationCode = gql`query verifyValidationCode($mobile: String!, $code: String!){
    verifyValidationCode(mobile: $mobile, code: $code) {
        code
        message
    }
}`

// 修改用户信息
export const updateMember = gql`mutation updateMember($member: MemberInput!) {
    updateMember(member: $member) {
        code
        message
        data {
            token
            expiresIn
        }
    }
}`

// 获取当前用户信息
export const findCurrentMember = gql`
    query findCurrentMember {
        findCurrentMember {
            code
            message
            data {
                id
                mobile
                name
                sex
                height
                age
            }
        }
    }
`

// 发送报告
export const sendReportByMail = gql`mutation sendReportByMail($scanId: String!, $email: String!, $unit: String!) {
    sendReportByMail(scanId: $scanId, email: $email, unit: $unit) {
        code
        message
    }
}`

// 发起合成
export const synthesis = gql`mutation synthesis($scanId: String!){
    synthesis(scanId: $scanId) {
        code
        message
    }
}`

// 新用户校验
export const verifyNewUser = gql`query verifyNewUser($mobile: String, $email: String){
    verifyNewUser(mobile: $mobile, email: $email) {
        code
        message
    }
}`

// 发送邮箱验证码
export const sendValidationEmail = gql`mutation sendValidationEmail($email: String!){
    sendValidationEmail(email: $email) {
        code
        message
    }
}`

// 邮箱新用户检验
export const verifyValidationEmail = gql`query verifyValidationEmail($email: String!, $code: String!){
    verifyValidationEmail(email: $email, code: $code) {
        code
        message
    }
}`

// 获取用户测量的历史记录
export const findUserMeasureHistory = gql`
    query findUserMeasureHistory ($deviceType: Int){
        findUserMeasureHistory (deviceType: $deviceType){
            code
            message
            data {
                year
                data {
                    scanId
                    createDate
                    createTime
                    biaMeasure
                    staticEval
                    shoulder
                    girthMeasure
                    
                    biaStatus
                    bdaStatus
                    evalStatus
                    bsShoulderStatus,
                    scanTime,
                    deviceId,
					utc
                }
            }
        }
    }
`


// 账号登录
export const accountLogin = gql`
    query accountLogin ($mobile: String, $email: String, $password: String){
        accountLogin (mobile: $mobile, email: $email, password: $password){
            code
            message
            data {
               token
               expiresIn
            }
        }
    }
`


// 重置密码
export const resetPassword = gql`query resetPassword ($mobile: String, $email: String, $code: String, $password: String, $type:String){
        resetPassword (mobile: $mobile, email: $email, code: $code, password: $password, type: $type){
            code
            message
        }
    }
`


// 检查客户是否存在
export const findAccount = gql`
    query findAccount ($mobile: String, $email: String, $code: String){
        findAccount (mobile: $mobile, email: $email, code: $code,){
            code
            message
        }
    }
`

 